import { Add, Remove } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { mobile, tablet } from "../responsive";
import StripeCheckout from "react-stripe-checkout";
import { useEffect, useState } from "react";
import { userRequest } from "../requestMethods";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { generateSignature } from "../redux/apiCalls";
import axios from "axios";
import { clearCart } from "../redux/cartRedux";

import {
    addQuantity,
    applyDiscount,
    clearDiscount,
    applyAffiliateCode,
} from "../redux/cartRedux";

const KEY = process.env.REACT_APP_STRIPE;

const Container = styled.div``;

const Wrapper = styled.div`
    padding: 20px;
    ${mobile({ padding: "10px" })}
`;

const Title = styled.h1`
    font-weight: 300;
    text-align: center;
`;

const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
`;

const TopButton = styled.button`
    padding: 10px;
    font-weight: 600;
    cursor: pointer;
    border: ${(props) => props.type === "filled" && "none"};
    background-color: ${(props) =>
        props.type === "filled" ? "black" : "transparent"};
    color: ${(props) => props.type === "filled" && "white"};
`;

const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    ${mobile({ flexDirection: "column" })}
    ${tablet({ flexDirection: "column" })}
`;

const Info = styled.div`
    flex: 3;
`;

const Product = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 3rem 0;
    ${mobile({ flexDirection: "column" })}
    ${tablet({ flexDirection: "column" })}
`;

const ProductDetail = styled.div`
    flex: 2;
    display: flex;
    ${mobile({ flexDirection: "column", alignItems: "center" })}
    ${tablet({ flexDirection: "column", alignItems: "center" })}
`;

const Image = styled.img`
    width: 200px;
`;

const Details = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    ${mobile({ padding: "2rem 1rem" })}
    ${tablet({ padding: "4rem 1rem" })}
`;

const ProductSize = styled.div`
    ${mobile({ padding: "0.25rem" })}
    ${tablet({ padding: "0.5rem" })}
`;

const PriceDetail = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${mobile({ flexDirection: "row", justifyContent: "space-around" })}
    ${tablet({ flexDirection: "row", justifyContent: "space-around" })}
`;

const ProductAmountContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const ProductAmount = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid teal;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    font-size: 24px;
    ${mobile({ height: "30px", width: "30px", margin: "5px 15px" })}
    ${tablet({ height: "30px", width: "30px", margin: "5px 15px" })}
`;

const ProductPrice = styled.div`
    font-size: 30px;
    font-weight: 200;
    ${mobile({ marginBottom: "20px", fontSize: "22px" })}
    ${tablet({ marginBottom: "20px", fontSize: "22px" })}
`;

const Hr = styled.hr`
    background-color: #eee;
    border: none;
    height: 1px;
`;

const Summary = styled.div`
    flex: 1;
    border: 0.5px solid lightgray;
    border-radius: 10px;
    padding: 20px;
    height: 100%;
`;

const SummaryTitle = styled.h1`
    font-weight: 200;
    ${mobile({ fontSize: "1.5rem" })}
    ${tablet({ fontSize: "2rem" })}
`;

const SummaryItem = styled.div`
    margin: 30px 0px;
    display: flex;
    justify-content: space-between;
    font-weight: ${(props) => props.type === "total" && "500"};
    font-size: ${(props) => props.type === "total" && "24px"};
`;

const Button = styled.button`
    width: 100%;
    padding: 10px;
    background-color: black;
    color: white;
    font-weight: 600;
    cursor: pointer;
`;

const SerialCode = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    margin: 30px 0px;
    font-weight: ${(props) => props.type === "total" && "500"};
    font-size: ${(props) => props.type === "total" && "24px"};
    align-items: stretch; /* Ensures both columns have the same height */
`;

const InputContainer = styled.div`
    width: 100%;
    height: 100%; /* Make it stretch to the grid cell height */
    background-color: white;
    display: flex;
    border: 1px solid lightgray;
`;

const Input = styled.input`
    border: none;
    flex: 8;
    padding-left: 20px;
    ${mobile({ fontSize: "0.8rem" })}
    ${tablet({ fontSize: "0.8rem" })}
`;

const SummaryItemText = styled.div`
    display: flex; /* Add flex behavior to adjust alignment */
    justify-content: center;
    align-items: center; /* Centers content vertically */
`;

const SummaryItemPrice = styled.div`
    display: flex; /* Add flex behavior to adjust alignment */
    justify-content: center;
    align-items: center; /* Centers content vertically */
`;


const Cart = () => {
    const cart = useSelector((state) => state.cart);
    const [stripeToken, setStripeToken] = useState(null);
    const [checkAffiliateCode, setCheckAffiliateCode] = useState(null);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [affiliateCode, setAffiliateCode] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user);

    let totalPrice = 0

    cart.products.forEach(item => {
        totalPrice += item.price * item.quantity;
    });

    const shippingCost = (cart.products.reduce((total, item) => {
        return total + item.quantity;
    }, 0) * 15);
    

    // cart.products.forEach(item => {
    //     let productDiscount;
    
    //     switch (item.model) {
    //         case "2323":
    //         case "1909L":
    //         case "1909L-1":
    //         case "2203":
    //         case "1901-1":
    //             productDiscount = 580;
    //             break;
    //         case "2290":
    //         case "2291":
    //             productDiscount = 880;
    //             break;
    //         case "2000":
    //             productDiscount = 900;
    //             break;
    //         case "1986":
    //             productDiscount = 380;
    //             break;
    //         case "1978":
    //             productDiscount = 480;
    //             break;
    //         default:
    //             productDiscount = 5; // Set default value if no match
    //             break;
    //     }
    
    //     totalDiscount += (productDiscount === 5 ? item.price * 0.05 : (productDiscount * item.quantity));
    // });

    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const { register, errors, handleSubmit, getValues } = useForm({
        defaultValues: {
            image: undefined,
            lineOne: currentUser.currentUser ? currentUser.currentUser.address ?  currentUser.currentUser.address.lineOne : "" : "",
            lineTwo: currentUser.currentUser ? currentUser.currentUser.address ?  currentUser.currentUser.address.lineTwo : "" : "",
            zip: currentUser.currentUser ? currentUser.currentUser.address ?  currentUser.currentUser.address.zip : "" : "",
            city: currentUser.currentUser ? currentUser.currentUser.address ?  currentUser.currentUser.address.city : "" : "",
            affiliateCode: undefined,
        },
    });

    const handleCreate = async () => {
        try {
            const formValues = getValues();
            let totalPrice = 0

            let updatedImage = undefined;

            if (formValues.image.length > 0) {
                const imageresult = await generateSignature();

                const updateImageFormData = new FormData();

                updateImageFormData.append("file", formValues.image[0]);
                updateImageFormData.append(
                    "publicKey",
                    "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
                );
                // ik signature expire token
                updateImageFormData.append(
                    "signature",
                    imageresult.data.signature
                );
                updateImageFormData.append(
                    "expire",
                    imageresult.data.expire
                );
                updateImageFormData.append(
                    "token",
                    imageresult.data.token
                );
                updateImageFormData.append(
                    "fileName",
                    `${formValues.image[0].name} - ${imageresult.data.expire}`
                );
                updateImageFormData.append("folder", "packages");

                const updateImageUploadResult = await axios.post(
                    "https://upload.imagekit.io/api/v1/files/upload",
                    updateImageFormData
                );

                updatedImage = updateImageUploadResult.data.url;
            }

            cart.products.forEach(item => {
                totalPrice += item.price * item.quantity;
            });

            const res = await userRequest.post("/orders", {
                userId: currentUser.currentUser._id,
                products: cart.products.map((item) => ({
                    productId: item._id,
                    quantity: item.quantity,
                    amount: item.quantity,
                    code: `${item.model}`,
                    img: item.color.color.img,
                    type: "new",
                    price: item.price,
                })),
                amount: totalPrice + shippingCost,
                discount: totalDiscount + cart.discount,
                affiliateCode: affiliateCode ? affiliateCode : undefined,
                address: `${formValues.lineOne} ${formValues.lineTwo} ${formValues.zip} ${formValues.city} ${formValues.country}`,
                cashier: currentUser ? currentUser._id : undefined,
                paymentType: "ecommerce",
                paymentproof:  updatedImage,
            });

            if (res.data) {
                alert("Order created successfully");
                dispatch(clearCart());
                window.location.href = "/order";
            }

        } catch (error) {
            console.error("Error creating order:", error);
            alert(
                "Failed to create order. Please check if there are enough slots available."
            );
        }
    };

    const onToken = (token) => {
        if (totalPrice * 100 <= 0) {
            alert("Total Cannot be less than RM0.00");
        } else {
            setStripeToken(token);
        }
    };
    

    const handleCheckSerialCode = async () => {
       if (checkAffiliateCode === "DEALER") {
            setTotalDiscount(shippingCost);
            setAffiliateCode("DEALER");
            setCheckAffiliateCode("DEALER")
       }
    };

    const handleQuantity = (product, quantity, amount, activeColor) => {
        dispatch(addQuantity({ ...product, quantity, amount, activeColor }));
    };

    const checkUserStatus = () => {
        alert("Please register an account or login first before checking out");
        window.location.href = "/login";
    };

    useEffect(() => {
        currentUser.currentUser === null
            ? checkUserStatus()
            : console.log("logged in");

        dispatch(clearDiscount());

        window.scrollTo(0, 0);
    }, [currentUser.currentUser, dispatch]);

    useEffect(() => {
        const makeRequest = async () => {
            try {
                const res = await userRequest.post("/checkout/payment", {
                    tokenId: stripeToken.id,
                    amount: totalPrice * 100,
                    discount: totalDiscount,
                });

                history.push("/success", {
                    address: res.data.billing_details.address,
                    gateway: "stripe",
                    products: cart,
                    serialCode: affiliateCode ? affiliateCode : undefined,
                });
            } catch {}
        };
        stripeToken && makeRequest();
    }, [stripeToken, cart, totalPrice, history, affiliateCode]);

    return (
        <Container>
            <Navbar />
            <Announcement />
            <Wrapper>
                <Title>YOUR BAG</Title>
                <Top>
                    <TopButton>
                        <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to="/"
                        >
                            CONTINUE SHOPPING
                        </Link>
                    </TopButton>
                </Top>
                <Bottom>
                    <Info>
                        {cart.products ? (
                            cart.products.map((product) => (
                                <Product key={product}>
                                    <ProductDetail>
                                        <Image
                                            src={
                                                product
                                                    ? product.color
                                                        ? product.color.color
                                                            ? product.color
                                                                  .color.img
                                                            : ""
                                                        : ""
                                                    : ""
                                            }
                                        />
                                        <Details>
                                            <ProductSize>
                                                <b>Product:</b> {product.title}
                                            </ProductSize>
                                            <ProductSize>
                                                <b>Model:</b> {product.model}
                                            </ProductSize>
                                            <ProductSize>
                                                <b>Price: </b>
                                                RM
                                                {product.price}
                                            </ProductSize>
                                        </Details>
                                    </ProductDetail>
                                    <PriceDetail>
                                        <ProductAmountContainer>
                                            <Add
                                                onClick={() =>
                                                    handleQuantity(
                                                        product,
                                                        1,
                                                        product.price,
                                                        product.color
                                                    )
                                                }
                                            />
                                            <ProductAmount>
                                                {product.quantity}
                                            </ProductAmount>
                                            <Remove
                                                onClick={() =>
                                                    handleQuantity(
                                                        product,
                                                        -1,
                                                        product.price -
                                                            product.price -
                                                            product.price,
                                                        product.color
                                                    )
                                                }
                                            />
                                        </ProductAmountContainer>
                                        <ProductPrice>
                                            RM
                                            {(
                                                product.price * product.quantity
                                            ).toLocaleString("en-US")}
                                        </ProductPrice>
                                    </PriceDetail>
                                </Product>
                            ))
                        ) : (
                            <></>
                        )}
                        <Hr />
                    </Info>
                    <Summary>
                        <SummaryTitle>ORDER SUMMARY</SummaryTitle>
                        <SummaryItem>
                            <SummaryItemText>Subtotal</SummaryItemText>
                            <SummaryItemPrice>
                                RM
                                {formatter.format(totalPrice)}
                            </SummaryItemPrice>
                        </SummaryItem>
                        <SummaryItem>
                            <SummaryItemText>
                                Estimated Shipping
                            </SummaryItemText>
                            <SummaryItemPrice>RM {formatter.format(shippingCost)}</SummaryItemPrice>
                        </SummaryItem>
                        <SummaryItem>
                            <SummaryItemText>Discount</SummaryItemText>
                            <SummaryItemPrice>
                                RM -{formatter.format(totalDiscount)}
                            </SummaryItemPrice>
                        </SummaryItem>

                        <SummaryItem type="total">
                            <SummaryItemText>Total</SummaryItemText>
                            <SummaryItemPrice>
                                RM
                                {formatter.format(totalPrice - totalDiscount + shippingCost)}
                            </SummaryItemPrice>
                        </SummaryItem>

                        <SerialCode>
                            <SummaryItemText>
                                {" "}
                                <InputContainer>
                                    <Input
                                        onChange={(e) =>
                                            setCheckAffiliateCode(
                                                e.target.value
                                            )
                                        }
                                        placeholder={"Serial Code"}
                                    />
                                </InputContainer>
                            </SummaryItemText>
                            <SummaryItemPrice>
                                {" "}
                                <Button onClick={() => handleCheckSerialCode()}>
                                    Apply{" "}
                                </Button>
                            </SummaryItemPrice>
                        </SerialCode>

                        {/* <StripeCheckout
                            name="Air Vitamin"
                            image="/favicon.ico"
                            billingAddress
                            shippingAddress
                            description={`Your total is RM ${formatter.format(
                                totalPrice - totalDiscount
                            )}`}
                            amount={(totalPrice- totalDiscount)  * 100 }
                            token={onToken}
                            stripeKey={KEY}
                            style={{ display: "none" }}
                            currency="MYR"
                        >
                            <Button>CHECKOUT NOW</Button>
                        </StripeCheckout> */}
<div>
<div>
    <div className="my-2 text-sm text-lynch font-medium">
        Address Line One
    </div>
    <input
        className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs border-gray-300`}
        {...register("lineOne")}
        type="text"
        placeholder={currentUser?.currentUser?.address?.lineOne || ""}
    />
</div>

<div>
    <div className="my-2 text-sm text-lynch font-medium">
        Address Line Two
    </div>
    <input
        className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs border-gray-300`}
        {...register("lineTwo")}
        type="text"
        placeholder={currentUser?.currentUser?.address?.lineTwo || ""}
    />
</div>

<div>
    <div className="my-2 text-sm text-lynch font-medium">
        Address Zip
    </div>
    <input
        className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs border-gray-300`}
        {...register("zip")}
        type="text"
        placeholder={currentUser?.currentUser?.address?.zip || ""}
    />
</div>

<div>
    <div className="my-2 text-sm text-lynch font-medium">
        Address City
    </div>
    <input
        className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs border-gray-300`}
        {...register("city")}
        type="text"
        placeholder={currentUser?.currentUser?.address?.city || ""}
    />
</div>
                       
                        
                        <div>
                            <div className="my-2 text-sm text-lynch font-medium">
                                Proof of Payment
                            </div>
                            <input
                                className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs border-gray-300`}
                                name="image"
                                {...register("image")}
                                type="file"
                                key="image"
                            />
                           
                        </div>
</div>
                        <Button  onClick={handleSubmit(handleCreate)}>SUBMIT ORDER</Button>
                    </Summary>
                </Bottom>
            </Wrapper>
            <Footer />
        </Container>
    );
};

export default Cart;
