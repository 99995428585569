import { Link } from "react-router-dom";
import styled from "styled-components";
import {
    List,
    Close,
    Person,
    ShoppingCartOutlined,
    Search,
} from "@material-ui/icons";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../redux/userRedux";
import { mobile, tablet } from "../responsive";
import { HiLockOpen } from "react-icons/hi";
import { clearCart } from "../redux/cartRedux";

const Container = styled.div``;

const MenuContainer = styled.div`
    display: none;
    ${mobile({
        display: "flex",
        alignItems: "center",
        padding: "10px 15px",
        background: "white",
        color: "black",
    })}
`;

const Dropddown = styled.div`
    padding-top: 15px;
    height: 100vh;
    color: black;
    display: flex;
    flex-direction: column;
`;

const NavbarAuth = styled.div`
    &.hidden {
        display: none;
    }
`;

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const Logo = styled.img`
    width: 80%;
    ${tablet({ width: "80%" })}
`;

const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items:center;
`;

const Center = styled.div`
    font-size: 1.5rem;
    cursor: pointer;
    font-family: FuturaPT-Bold;
    flex: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Right = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const MobileNavbar = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [showSeries, setShowSeries] = useState(false);
    const currentUser = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(logout());
        dispatch(clearCart())
    };
    return (
        <>
            <Container>
                {" "}
                <MenuContainer>
                    {" "}
                    <Left>
                        {" "}
                        {showMenu ? (
                            <Close
                                onClick={() => {
                                    setShowSeries(false);
                                    setShowMenu(false);
                                }}
                            ></Close>
                        ) : (
                            <>
                                <List
                                    onClick={() => {
                                        setShowSeries(false);
                                        setShowMenu(true);
                                    }}
                                ></List>
                            </>
                        )}
                        <Link
                            style={{ textDecoration: "none", color: "black", }}
                            to={{
                                pathname: "/search",
                                state: {
                                    model: "",
                                },
                            }}
                        >
                            <Search
                            ></Search>
                        </Link>
                    </Left>
                    <Center>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",
                            }}
                            to="/"
                        >
                            <LogoContainer>
                            <Logo
                                src={
                                    "https://ik.imagekit.io/dnddecpho/airvitamin/airvitaminlogo_H8VCTUWjn.png?updatedAt=1732805836265"
                                }
                            ></Logo>
                            </LogoContainer>
                        </Link>
                    </Center>
                    <Right>
                        <Link
                            to="/login"
                            style={
                                currentUser.currentUser === null
                                    ? {
                                          textDecoration: "none",
                                          color: "black",
                                      }
                                    : { display: "none" }
                            }
                        >
                            <HiLockOpen
                                className={
                                    currentUser.currentUser !== null
                                        ? "hidden"
                                        : null
                                }
                                style={{
                                    fontSize: "22px",
                                }}
                                onClick={() => handleClick()}
                            ></HiLockOpen>
                        </Link>

                        <Link
                            to="/profile"
                            style={
                                currentUser.currentUser !== null
                                    ? {
                                          textDecoration: "none",
                                          color: "black",
                                      }
                                    : { display: "none" }
                            }
                        >
                            <Person
                                className={
                                    currentUser.currentUser === null
                                        ? "hidden"
                                        : null
                                }
                            ></Person>
                        </Link>

                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",
                            }}
                            to="/cart"
                        >
                            <NavbarAuth
                            >
                                <ShoppingCartOutlined></ShoppingCartOutlined>
                            </NavbarAuth>
                        </Link>
                    </Right>
                </MenuContainer>
                {showMenu && (
                    <Container>
                        <Dropddown>
                            {" "}
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                to="/"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                Home
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                to="/about"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                About Us
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                to="/background"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                Background Story
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                to="/demo"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                Demonstration
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                to="/gallery"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                Gallery
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                to="/product/G10"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                The Product
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                to="/search"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                Service & Repair
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                to="/contact"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                Contact Us
                            </Link>
                            <NavbarAuth
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                className={
                                    currentUser.currentUser == null
                                        ? "hidden"
                                        : null
                                }
                                onClick={() => handleClick()}
                            >
                                Logout
                            </NavbarAuth>
                        </Dropddown>
                    </Container>
                )}
                {showSeries && (
                    <Container>
                        <Dropddown>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                onClick={() => {
                                    setShowSeries(false);
                                    setShowMenu(false);
                                }}
                                to="/products/self-winding"
                            >
                                Self-Winding Tourbillon
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                onClick={() => {
                                    setShowSeries(false);
                                    setShowMenu(false);
                                }}
                                to="/products/manual"
                            >
                                Manual Tourbillon
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                onClick={() => {
                                    setShowSeries(false);
                                    setShowMenu(false);
                                }}
                                to="/products/crystal"
                            >
                                Crystal Series
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                onClick={() => {
                                    setShowSeries(false);
                                    setShowMenu(false);
                                }}
                                to="/products/carousel"
                            >
                                Carousel Series
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                onClick={() => {
                                    setShowSeries(false);
                                    setShowMenu(false);
                                }}
                                to="/products/mechanical"
                            >
                                Automatic Mechanical
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                onClick={() => {
                                    setShowSeries(false);
                                    setShowMenu(false);
                                }}
                                to="/products/women"
                            >
                                Ladies Series
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                onClick={() => {
                                    setShowSeries(false);
                                    setShowMenu(false);
                                }}
                                to="/products/all"
                            >
                                All Series
                            </Link>
                        </Dropddown>
                    </Container>
                )}
            </Container>
        </>
    );
};

export default MobileNavbar;
