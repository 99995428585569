import { useEffect, useState } from "react";
import styled from "styled-components";
import Product from "./AlternateProduct";
import { mobile, tablet } from "../responsive";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { publicRequest } from "../requestMethods";

const MainContainer = styled.div`
    display: flex;
    padding: 4rem 0;
    ${mobile({ padding: "0px" })}
    ${tablet({ padding: "0px" })}
`;

const Container = styled.div`
    padding: 0 3rem;
    flex-grow: 5;
    display: grid;
    grid-gap: 2.5rem 0;
    grid-template-columns: auto auto auto;
    flex-wrap: wrap;
    justify-content: space-between;
    ${mobile({ display: "grid", gridTemplateColumns: "50% 50%", padding: "1rem 0 2rem 0" })}
    ${tablet({ display: "grid", gridTemplateColumns: "50% 50%", padding: "1rem 0 2rem 0" })}
`;

const FilterContainer = styled.div`
    display: block;
    width: 100vh;
    flex-grow: 2;
    ${mobile({ display: "none" })}
    ${tablet({ display: "none" })}
`;

const FilterTitle = styled.div`
    text-align: left;
    margin: 2rem 3rem;
    font-size: 1.5rem;
    font-weight: 600;
    ${mobile({ display: "none" })}
    ${tablet({ display: "none" })}
`;

const FilterItem = styled.div`
    text-align: left;
    margin: 1rem 3rem;
    font-size: 1rem;
    &:hover {
        font-family: "FuturaPT-Demi";
    }
    ${mobile({ display: "none" })}
    ${tablet({ display: "none" })}
`;

const Products = ({ cat, filters, sort }) => {
    const location = useLocation();
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        const getProducts = async () => {
            try {
                const res = await publicRequest.get(
                    cat === "all"
                        ? `/products`
                        : `/products?category=${cat}`
                );
                const filteredData = (cat !== "all" ? res.data.filter((product) => product.categories.includes(cat)): res.data)
                setProducts(filteredData);
            } catch (err) {}
        };
        getProducts();
    }, [cat]);

    useEffect(() => {
        cat &&
            setFilteredProducts(
                products.filter((item) =>
                    Object.entries(filters).every(([key, value]) =>
                        item[key].includes(value)
                    )
                )
            );
    }, [products, cat, filters]);

    useEffect(() => {
        if (sort === "newest") {
            setFilteredProducts((prev) =>
                [...prev].sort((a, b) => a.createdAt - b.createdAt)
            );
        } else if (sort === "asc") {
            setFilteredProducts((prev) =>
                [...prev].sort((a, b) => a.price - b.price)
            );
        } else {
            setFilteredProducts((prev) =>
                [...prev].sort((a, b) => b.price - a.price)
            );
        }
    }, [sort]);

    return (
        <MainContainer>
            <FilterContainer>
                <FilterTitle>Series</FilterTitle>
                <Link
                    style={
                        location.pathname === "/products/self-winding"
                            ? {
                                  textDecoration: "none",
                                  color: "#ac997b",
                                  fontFamily: "FuturaPT-Demi",
                              }
                            : { textDecoration: "none", color: "black" }
                    }
                    to={"/products/self-winding"}
                >
                    <FilterItem>Self-winding Tourbillon</FilterItem>
                </Link>
                <Link
                    style={
                        location.pathname === "/products/manual"
                            ? {
                                  textDecoration: "none",
                                  color: "#ac997b",
                                  fontFamily: "FuturaPT-Demi",
                              }
                            : { textDecoration: "none", color: "black" }
                    }
                    to={"/products/manual"}
                >
                    <FilterItem>Manual Tourbillon</FilterItem>
                </Link>
                <Link
                    style={
                        location.pathname === "/products/crystal"
                            ? {
                                  textDecoration: "none",
                                  color: "#ac997b",
                                  fontFamily: "FuturaPT-Demi",
                              }
                            : { textDecoration: "none", color: "black" }
                    }
                    to={"/products/crystal"}
                >
                    <FilterItem>Crystal Series</FilterItem>
                </Link>
                <Link
                    style={
                        location.pathname === "/products/carousel"
                            ? {
                                  textDecoration: "none",
                                  color: "#ac997b",
                                  fontFamily: "FuturaPT-Demi",
                              }
                            : { textDecoration: "none", color: "black" }
                    }
                    to={"/products/carousel"}
                >
                    <FilterItem>Carousel Series</FilterItem>
                </Link>
                <Link
                    style={
                        location.pathname === "/products/mechanical"
                            ? {
                                  textDecoration: "none",
                                  color: "#ac997b",
                                  fontFamily: "FuturaPT-Demi",
                              }
                            : { textDecoration: "none", color: "black" }
                    }
                    to={"/products/mechanical"}
                >
                    <FilterItem>Automatic Mechanical</FilterItem>
                </Link>
                <Link
                    style={
                        location.pathname === "/products/women"
                            ? {
                                  textDecoration: "none",
                                  color: "#ac997b",
                                  fontFamily: "FuturaPT-Demi",
                              }
                            : { textDecoration: "none", color: "black" }
                    }
                    to={"/products/women"}
                >
                    <FilterItem>Ladies Series</FilterItem>
                </Link>
                <Link
                    style={
                        location.pathname === "/products/women"
                            ? {
                                  textDecoration: "none",
                                  color: "#ac997b",
                                  fontFamily: "FuturaPT-Demi",
                              }
                            : { textDecoration: "none", color: "black" }
                    }
                    to={"/products/all"}
                >
                    <FilterItem>All Series</FilterItem>
                </Link>
            </FilterContainer>

            <Container>
                {cat !== "all"
                    ? filteredProducts.map((item) => (
                          <Product item={item} key={`${item.title}`} />
                      ))
                    : products.map((item) => (
                        <Product item={item} key={`${item.title}`} />
                    ))}
            </Container>
        </MainContainer>
    );
};

export default Products;
