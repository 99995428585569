
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import About from "../components/About";
import Certificate from "../components/Certificate";

const AboutUs = () => {
    return (
        <div>
            <Navbar />
            <Certificate />
            <About />

            <Newsletter />
            <Footer />
        </div>
    );
};

export default AboutUs;
