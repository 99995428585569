import Product from "./pages/Product";
import Home from "./pages/Home";
import ProductList from "./pages/ProductList";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Cart from "./pages/Cart";
import Delivery from "./pages/Delivery";
import Refund from "./pages/Refund";
import Contact from "./pages/Contact";
import Order from "./pages/Order";
import Warranty from "./pages/Warranty";
import AboutUs from "./pages/About";
import Search from "./pages/Search";
import Demo from "./pages/Demo";
import Background from "./pages/Background";
import styled from "styled-components";
import ForgotPassword from "./pages/ForgotPassword";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import Success from "./pages/Success";
import { useSelector } from "react-redux";
import { WhatsApp } from "@material-ui/icons";
import ResetPassword from "./pages/ResetPassword";
import Profile from "./pages/Profile";
import { ToastContainer } from "react-toastify";
import Complete from "./pages/Complete";
import Gallery from "./pages/Gallery";

const GlobalContainer = styled.div`
    font-family: "FuturaPT-Book";
`;

const WhatsappButton = styled.a`
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 104;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const WhatsappIcon = styled.i``;

const App = () => {
    const user = useSelector((state) => state.user.currentUser);

    return (
        <GlobalContainer>
            <WhatsappButton
                href="https://api.whatsapp.com/send?phone=60123348626"
                target="blank"
            >
                <WhatsappIcon className="flex justify-center align-center">
                    <WhatsApp></WhatsApp>
                </WhatsappIcon>
            </WhatsappButton>
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/products/:category">
                        <ProductList />
                    </Route>
                    <Route path="/product/:model">
                        <Product />
                    </Route>
                    <Route path="/demo/:model">
                        <Product />
                    </Route>
                    <Route path="/cart">
                        <Cart />
                    </Route>
                    <Route path="/warranty">
                        <Warranty />
                    </Route>
                    <Route path="/order">
                        <Order />
                    </Route>
                    <Route path="/privacy">
                        <Privacy />
                    </Route>
                    <Route path="/refund">
                        <Refund />
                    </Route>
                    <Route path="/delivery">
                        <Delivery />
                    </Route>
                    <Route path="/terms">
                        <Terms />
                    </Route>
                    <Route path="/contact">
                        <Contact />
                    </Route>
                    <Route path="/about">
                        <AboutUs />
                    </Route>
                    <Route path="/complete">
                        <Complete />
                    </Route>
                    <Route path="/success">
                        <Success />
                    </Route>
                    <Route path="/search">
                        <Search />
                    </Route>
                    <Route path="/demo">
                        <Demo />
                    </Route>
                    <Route path="/gallery">
                        <Gallery />
                    </Route>
                    <Route path="/background">
                        <Background />
                    </Route>
                    <Route path="/login">
                        {user ? <Redirect to="/" /> : <Login />}
                    </Route>
                    <Route path="/profile">
                        {user ? <Profile /> : <Login />}
                    </Route>
                    <Route path="/reset">
                        {user ? <Redirect to="/" /> : <ForgotPassword />}
                    </Route>
                    <Route path="/resetpassword/:token">
                        {user ? <Redirect to="/" /> : <ResetPassword />}
                    </Route>
                    <Route path="/register">
                        {user ? <Redirect to="/" /> : <Register />}
                    </Route>
                </Switch>
                <ToastContainer
                    style={{ width: "fit-content", overflow: "hidden" }}
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Router>
        </GlobalContainer>
    );
};

export default App;
