import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "airvitamincart",
    initialState: {
        products: [],
        quantity: 0,
        total: 0,
        discount: 0,
        affiliateCode: undefined,
    },
    reducers: {
        addProduct: (state, action) => {
            const product = action.payload;
            // const finalDiscount = product.model === "2323" || product.model === "1909L" || product.model === "1909L-1" || product.model === "2203" || product.model === "1901-1" ? 580 :
            // product.model === "2290" || product.model === "2291" ? 880 :
            // product.model === "2000" ? 900 :
            // product.model === "1986" ?  380 :
            // product.model === "1978" ?  480 :
            // product.price > 3000 ? product.price * 0.05 :
            // 0;
            // const finalPrice = product.model === "2323" || product.model === "1909L" || product.model === "1909L-1" || product.model === "2203" || product.model === "1901-1" ? product.price - 580 :
            // product.model === "2290" || product.model === "2291" ? product.price - 880 :
            // product.model === "2000" ? product.price - 900 :
            // product.model === "1986" ? product.price - 380 :
            // product.model === "1978" ? product.price - 480 :
            // product.price > 3000 ? product.price * 0.95 :
            // product.price;
            const finalDiscount = 0
            const finalPrice = product.price
            state.quantity += 1;
            state.products.push({
                _id: product._id,
                title: product.title,
                color: product.activeColor,
                model: product.model,
                price: product.price,
                movementModel: product.movementModel,
                powerReserve: product.powerReserve,
                mirror: product.mirror,
                quantity: product.quantity,
            });
            state.discount += finalDiscount
            state.total += finalPrice * product.quantity;
        },
        addQuantity: (state, action) => {
            const existingProductIndex = state.products.findIndex(
                (item) =>
                    `${item.title}-${item.color.name}` ===
                    `${action.payload.title}-${action.payload.activeColor.name}`
            );
            const discount = state.discount / state.products[existingProductIndex].quantity
            state.products[existingProductIndex].quantity =
                state.products[existingProductIndex].quantity +
                action.payload.quantity;
            state.total =
                state.total + action.payload.price * action.payload.quantity;
            state.discount = state.discount + (discount * action.payload.quantity)
            if (state.products[existingProductIndex].quantity <= 0) {
                state.products.splice(existingProductIndex, 1);
                state.quantity -= 1;
            }
        },
        clearCart: (state) => {
            state.products = [];
            state.quantity = 0;
            state.total = 0;
        },
        applyDiscount: (state, action) => {
            state.discount = action.payload;
        },
        applyAffiliateCode: (state, action) => {
            state.affiliateCode = action.payload;
        },
        clearDiscount: (state) => {
            state.discount = 0;
        },
    },
});

export const {
    addProduct,
    addQuantity,
    clearCart,
    applyDiscount,
    applyAffiliateCode,
    clearDiscount,
} = cartSlice.actions;
export default cartSlice.reducer;
