import { useState } from "react";
import styled from "styled-components";

const GalleryContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
`;

const MainImageContainer = styled.div`
  width: 80%;
  max-width: 1200px;
  height: 70vh;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const MainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ThumbnailGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 10px;
  width: 100%;
  max-width: 1200px;
`;

const ThumbnailContainer = styled.div`
  position: relative;
  border: ${(props) =>
    props.isSelected ? "2px solid #333" : "2px solid transparent"};
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: border-color 0.3s ease;
  &:hover {
    border-color: #666;
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Certificate = () => {
    const images = [
        {
          id: 1,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG_COM_202411291820030810_fTuirdXtc.jpeg?updatedAt=1732899778083"
        },
        {
          id: 2,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG_COM_202411291820031561_U0jAfmads.jpeg?updatedAt=1732899778037"
        },
        {
          id: 3,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG_COM_202411291814282583_EpEh00bjI.jpg?updatedAt=1732899778135"
        },
        {
          id: 4,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG_COM_202411291814281360_YkN7shLKj.jpg?updatedAt=1732899778080"
        },
        {
          id: 5,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG_COM_202411291814282302_wX67dpfAf6.jpg?updatedAt=1732899778041"
        },
        {
          id: 6,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG_COM_202411291814283416_ZF1K7zLNm.jpg?updatedAt=1732899777981"
        },
        {
            id: 7,
            url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG_COM_202411291814281981_5uiNAWwd6M.jpg?updatedAt=1732899778131"
          },
          {
            id: 8,
            url:  "https://ik.imagekit.io/dnddecpho/airvitamin/IMG_COM_202411291814283165_9TnRH_O8NJ.jpg?updatedAt=1732899778085"
          },
          {
            id: 9,
            url:          "https://ik.imagekit.io/dnddecpho/airvitamin/IMG_COM_202411291814282834__VSgeKCac9.jpg?updatedAt=1732899778113"
          },

      ];
      

  const [mainImage, setMainImage] = useState(images[0].url);

  const handleThumbnailClick = (url) => {
    setMainImage(url);
  };

  return (
    <GalleryContainer>
      <MainImageContainer>
        <MainImage src={mainImage} alt="Main Display" />
      </MainImageContainer>
      <ThumbnailGrid>
        {images.map((image) => (
          <ThumbnailContainer
            key={image.id}
            isSelected={mainImage === image.url}
            onClick={() => handleThumbnailClick(image.url)}
          >
            <Thumbnail src={image.url} alt={`Thumbnail ${image.id}`} />
          </ThumbnailContainer>
        ))}
      </ThumbnailGrid>
    </GalleryContainer>
  );
};

export default Certificate;
