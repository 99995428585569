import styled from "styled-components";
import { mobile, tablet } from "../responsive";

const Container = styled.div`
    background: #f5fafd;
    display: block;
    height: 100%;
    ${mobile({ padding: "0", display: "flex", flexDirection: "column", paddingTop: "4rem" })}
    ${tablet({ padding: "0", display: "flex", flexDirection: "column" })}
`;

const Title = styled.h1`
    padding: 4rem 0;
    width: 60%;
    font-family: FuturaPT-Bold;
    font-size: 2.5rem;
    color: #ac997b;
    text-align: center;
    margin: 0;
    ${mobile({
        display: "block",
        textAlign: "center",
        fontSize: "1.5rem",
        padding: "0 2rem",
        width: "90%"
    })}
    ${tablet({
        display: "block",
        textAlign: "center",
        fontSize: "1.5rem",
        padding: "0 2rem",
    })}
`;

const AboutContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    ${mobile({ display: "block", width: "90%" })}
    ${tablet({ display: "block", width: "90%"  })}
`;

const Desc = styled.div`
    margin: 3rem;
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 1.5;

    ${mobile({ display: "block", fontSize: "1.3rem" })}
    ${tablet({ display: "block", fontSize: "1.3rem" })}
`;

const LeftA = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    order: 1;
    ${mobile({ height: "100%" })}
    ${tablet({ height: "100%", paddingTop: "6rem" })}
`;

const Button = styled.button`
    width: 60%;
    border: 1px black solid;
    padding: 1rem;
    margin-bottom: 4rem;
    background-color: black;
    color: white;
    cursor: pointer;
    font-size: 1.2rem;
    font-family: FuturaPT-Bold;
    &:hover {
        background: #ead61c;
        color: black;
    }
    ${mobile({ width: "90%" })}
`;

const CompleteComponent = () => {
    return (
        <Container>
            <LeftA>
                <Title>Welcome to the Air Vitamin Family.</Title>
                <AboutContainer>
                    <Desc>
                        I hope this message finds you well and filled with anticipation for the arrival of your new Air Vitamin.
                    </Desc>
                    <Desc>
                        We wanted to take a moment to express our heartfelt gratitude for choosing our brand. Your decision to invest in our craftsmanship means the world to us. It is customers like you who inspire us to continually strive for excellence in every aspect of what we do.
                    </Desc>
                    <Desc>
                        Rest assured, your order is in good hands, and we are working diligently to ensure that your watch is carefully prepared and swiftly shipped to you. We understand the excitement of eagerly awaiting a new addition to your collection, and we are just as excited to deliver it to you.
                    </Desc>
                    <Desc>
                        In the meantime, if you have any questions or need assistance with anything, please don't hesitate to reach out to our customer service team at +6 010-200-6200 or haofa.cshelp@gmail.com. We are here to ensure your experience with Air Vitamin is nothing short of exceptional.
                    </Desc>
                    <Desc>
                        Once again, thank you for choosing Air Vitamin. We are truly honored to be a part of your journey in life.
                    </Desc>
<div className="flex justify-center">
<Button onClick={() => window.location.href="/"}>
                        Home
                    </Button>
</div>
                </AboutContainer>
            </LeftA>
        </Container>
    );
};

export default CompleteComponent;
