import { useState, useRef } from "react";
import styled from "styled-components";
import { login } from "../redux/apiCalls";
import { mobile, tablet } from "../responsive";
import { useDispatch } from "react-redux";
import Navbar from "../components/Navbar";
import Announcement from "../components/Announcement";
import Newsletter from "../components/Newsletter";
import { toast } from "react-toastify";
import Footer from "../components/Footer";

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("https://ik.imagekit.io/dnddecpho/airvitamin/purifierbg_OTgVggxOQ?updatedAt=1732806316006")
            bottom;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    ${mobile({ display: "block", height: "100%", background: "none" })}
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 450px;
    margin: 5rem;
    width: 40%;
    padding: 16px 32px;
    background-color: white;
    min-width: 300px;
    ${mobile({
        width: "100%",
        margin: "0rem",
        height: "100%",
        padding: "0px 16px"
    })}
    ${tablet({
        width: "85%",
        margin: "0rem",
        height: "80%",
        padding: "0px 16px"
    })}
`;

const Heading = styled.h1`
    font-size: 28px;
    color: #1f2f4d;
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
    font-family: FuturaPT-Bold;
`;

const Line = styled.hr`
    width: 20%;
    margin-left: auto;
    margin-right: auto;
`;

const Title = styled.h1`
font-family: FuturaPT-Bold;
    font-size: 18px;
    color: #1f2f4d;
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
`;

const Form = styled.div`
    display: block;
    flex-wrap: wrap;
    ${mobile({ display: "flex", flexDirection: "column" })}
`;

const Input = styled.input`
    flex: 1;
    min-width: 100%;
    padding: 14px;
    ${mobile({ minWidth: "100%"})}
`;

const LinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const Link = styled.a`
    margin: 20px 0px;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    font-family: Tahoma;
`;

const Button = styled.button`
    width: 100%;
    border: none;
    padding: 15px 20px;
    background-color: #1f2f4d;
    color: white;
    cursor: pointer;
    margin: 1rem 0;
    border-radius: 30px;
`;

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const form = useRef();

    const handleOnSubmit = async() => {
        const result = await login(dispatch, { email, password });

        if (result) {
            toast.success(
                `Welcome back, ${result}`,
                {
                    position: "top-center",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
        } else {
            toast.error(
                `Username or password is incorrect.`,
                {
                    position: "top-center",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
        }
        
    };
    return (
        <>
            <Navbar></Navbar>
            <Announcement />
            <Container>
                <Wrapper>
                    <Heading>LOGIN</Heading>
                    <Line />

                    <Form ref={form}>
                        <Title>Email</Title>
                        <Input
                            id="grid-first-name"
                            name="login"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <Title>Password</Title>
                        <Input
                            id="grid-last-name"
                            name="password"
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <LinkContainer>
                            <Link href="/reset">FORGOT YOUR PASSWORD?</Link>
                        </LinkContainer>
                        <Button onClick={handleOnSubmit}>SIGN IN</Button>
                        <LinkContainer>
                            <Link href="/register">CREATE NEW ACCOUNT</Link>
                        </LinkContainer>
                        <LinkContainer>
                            <Link href="/">RETURN TO STORE</Link>
                        </LinkContainer>
                    </Form>
                </Wrapper>
            </Container>
            <Newsletter></Newsletter>
            <Footer></Footer>
        </>
    );
};

export default Login;
