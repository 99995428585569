import { Badge } from "@material-ui/core";
import {
    Search,
    ShoppingCartOutlined,
    List,
    Close,
    ExitToApp,
    Person,
    Archive,
} from "@material-ui/icons";
import { HiLockOpen } from "react-icons/hi";
import styled from "styled-components";
import { mobile, tablet } from "../responsive";
import { Link, useHistory } from "react-router-dom";
import { logout } from "../redux/userRedux";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import MobileNavbar from "./MobileNavbar";
import { clearCart } from "../redux/cartRedux";

const Container = styled.div`
    height: 80px;
    ${mobile({ height: "inherit" })}
`;

const MenuContainer = styled.div`
    padding-top: 30px;
`;

const Wrapper = styled.div`
    padding: 10px 50px;
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    ${mobile({ padding: "10px 0px", display: "none" })}
`;

const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
`;

const LeftContainer = styled.div`
    cursor: pointer;
    margin-right: 30px;
    display: flex;
    align-items: center;
`;

const RightContainer = styled.div`
    cursor: pointer;
    margin-right: 30px;
    display: flex;
    align-items: center;

    ${mobile({ display: "none" })}
    ${tablet({ display: "none" })}
`;

const Center = styled.div`
    flex: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Cursor = styled.div`
    cursor: pointer;
    textdecoration: "none";
    color: "black";
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Logo = styled.img`
    max-height: 50px;
    ${mobile({ maxHeight: "4vh", marginLeft: "5vh" })}
`;

const Right = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ${mobile({ flex: 2, justifyContent: "center" })}
`;

const MenuItem = styled.div`
    font-size: 14px;
    cursor: pointer;
    margin-left: 25px;
    ${mobile({ fontSize: "12px", marginLeft: "0px" })}
`;

const NavbarAuth = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    ${mobile({ fontSize: "12px", marginLeft: "10px" })}
    &.hidden {
        display: none;
    }
`;

const ModalContainer = styled.div`
    height: 100%;
    width: 100%;
    opacity: 0.75;
    background: black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 10;
`;

const OverlayContainer = styled.div`
    overflow: hidden;
`;

const ModalTitle = styled.h1`
    cursor: pointer;
    margin: 1rem 0rem;
    font-size: 20px;
    font-weight: 300;
    text-align: left;
    padding: 0;
    color: gray;
    padding-bottom: 10px;
    border-bottom: 1px solid white;
    &:hover {
        padding-bottom: 10px;
        border-bottom: 1px solid #d7ba94;
    }
    ${tablet({ fontSize: "1rem" })}
`;

const Overlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(white, 0.5);
    visibility: visible;
`;

const ModalDetails = styled.div`
    background-color: rgba(white, 0.5);
    color: black;
    height: 100%;
    width: 33.33%;
    display: flex;
    z-index: 11;
    ${mobile({ width: "100%", height: "100%" })}
`;

const ModalItemContainer = styled.div`
    border-right: 1px solid black;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 2rem 1.5rem 2rem 3.5rem;
    width: 100%;
    background-color: white;
    z-index: 13;
`;

const InputContainer = styled.div`
    width: 100%;
    height: 40px;
    background-color: white;
    display: flex;
    border: 1px solid lightgray;
    margin-right: 10px;
    ${tablet({ width: "50%", display: "none" })}
`;

const Input = styled.input`
    border: none;
    flex: 8;
    padding-left: 20px;
    ${mobile({ fontSize: "0.8rem" })}
`;

const ScrollContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 40px;
    border: none;
    background-color: #1f2f4d;
    color: white;
    overflow: hidden;
`;

const ScrollText = styled.div`
text-align: center;
display: block;
${mobile({ display: "none" })}
/* animation properties */
-moz-transform: translateX(-100%);
-webkit-transform: translateX(-100%);
transform: translateX(-100%);

-moz-animation: my-animation 15s linear infinite;
-webkit-animation: my-animation 15s linear infinite;
animation: my-animation 15s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
from { -moz-transform: translateX(-100%); }
to { -moz-transform: translateX(100%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
from { -webkit-transform: translateX(-100%); }
to { -webkit-transform: translateX(100%); }
}

@keyframes my-animation {
from {
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
to {
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}


`;

const MobileScrollText = styled.div`
text-align: center;
display: none;
${mobile({ display: "block" })}
/* animation properties */
-moz-transform: translateX(-100%);
-webkit-transform: translateX(-100%);
transform: translateX(-100%);

-moz-animation: my-animation 15s linear infinite;
-webkit-animation: my-animation 15s linear infinite;
animation: my-animation 15s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
from { -moz-transform: translateX(-100%); }
to { -moz-transform: translateX(100%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
from { -webkit-transform: translateX(-100%); }
to { -webkit-transform: translateX(100%); }
}

@keyframes my-animation {
from {
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
to {
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}


`;

const Navbar = () => {
    const quantity = useSelector((state) => state.cart.quantity);
    const currentUser = useSelector((state) => state.user);
    const [showMenu, setShowMenu] = useState(false);
    const [model, setModel] = useState(undefined);
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(logout());
        dispatch(clearCart());
    };

    const history = useHistory();

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            history.push({
                pathname: "/search",
                state: { model: model },
            });
        }
    };

    useEffect(() => {
        if (showMenu === true) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [showMenu]);

    return (
        <>
            <Container>
                <Wrapper>
                    {" "}
                    <Left>
                        <Cursor>
                            <ul onClick={() => setShowMenu(!showMenu)}>
                                {showMenu ? (
                                    <LeftContainer>
                                        <Close></Close>Menu
                                    </LeftContainer>
                                ) : (
                                    <LeftContainer>
                                        <List></List>Menu
                                    </LeftContainer>
                                )}
                            </ul>
                        </Cursor>

                        <LeftContainer>
                            <InputContainer>
                                <Input
                                    onChange={(e) => setModel(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    placeholder={"Search here"}
                                />
                            </InputContainer>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to={{
                                    pathname: "/search",
                                    state: {
                                        model: model,
                                    },
                                }}
                            >
                                <Search></Search>{" "}
                            </Link>
                        </LeftContainer>
                    </Left>
                    <Center>
                        <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to="/"
                        >
                            <Logo
                                src={
                                    "https://ik.imagekit.io/dnddecpho/airvitamin/airvitaminlogo_H8VCTUWjn.png?updatedAt=1732805836265"
                                }
                            ></Logo>
                        </Link>
                    </Center>
                    {currentUser ? currentUser.currentUser !== null ? (
                        <Right>
                            <RightContainer>
                                Welcome, {currentUser ? currentUser.currentUser ? currentUser.currentUser.name : "" : ""}
                            </RightContainer>

                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/login"
                            >
                                <NavbarAuth
                                    className={
                                        currentUser.currentUser !== null
                                            ? "hidden"
                                            : null
                                    }
                                >
                                    <HiLockOpen
                                        style={{ fontSize: "23px" }}
                                    ></HiLockOpen>
                                </NavbarAuth>
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/cart"
                            >
                                <MenuItem>
                                    <Badge
                                        badgeContent={quantity}
                                        color="primary"
                                    >
                                        <ShoppingCartOutlined />
                                    </Badge>
                                </MenuItem>
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                    marginRight: "20px",
                                    marginLeft: "20px",
                                }}
                                to="/order"
                            >
                                <NavbarAuth
                                    className={
                                        currentUser.currentUser === null
                                            ? "hidden"
                                            : null
                                    }
                                >
                                    <Archive></Archive>
                                </NavbarAuth>
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                    marginRight: "20px",
                                }}
                                to="/profile"
                            >
                                <NavbarAuth
                                    className={
                                        currentUser.currentUser === null
                                            ? "hidden"
                                            : null
                                    }
                                >
                                    <Person></Person>
                                </NavbarAuth>
                            </Link>

                            <div
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                    cursor: "pointer",
                                }}
                                className={
                                    currentUser.currentUser == null
                                        ? "hidden"
                                        : null
                                }
                                onClick={handleClick}
                            >
                                <NavbarAuth
                                    className={
                                        currentUser.currentUser == null
                                            ? "hidden"
                                            : null
                                    }
                                >
                                    <ExitToApp></ExitToApp>
                                </NavbarAuth>
                            </div>
                        </Right>
                    ) : (
                        <></>
                    ) : (
                        <></>
                    )}
                    {currentUser ?currentUser.currentUser === null ? (
                        <Right>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                    marginRight: "20px",
                                    marginLeft: "20px",
                                }}
                                to="/order"
                            >
                                <NavbarAuth
                                    className={
                                        currentUser.currentUser === null
                                            ? "hidden"
                                            : null
                                    }
                                >
                                    <Archive></Archive>
                                </NavbarAuth>
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                    marginRight: "20px",
                                }}
                                to="/profile"
                            >
                                <NavbarAuth
                                    className={
                                        currentUser.currentUser === null
                                            ? "hidden"
                                            : null
                                    }
                                >
                                    <Person></Person>
                                </NavbarAuth>
                            </Link>

                            <RightContainer>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/register"
                                >
                                    <NavbarAuth
                                        className={
                                            currentUser.currentUser !== null
                                                ? "hidden"
                                                : null
                                        }
                                    >
                                        Register
                                    </NavbarAuth>
                                </Link>
                            </RightContainer>

                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/login"
                            >
                                <NavbarAuth
                                    className={
                                        currentUser.currentUser !== null
                                            ? "hidden"
                                            : null
                                    }
                                >
                                    <HiLockOpen
                                        style={{ fontSize: "23px" }}
                                    ></HiLockOpen>
                                </NavbarAuth>
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/cart"
                            >
                                <MenuItem>
                                    <Badge
                                        badgeContent={quantity}
                                        color="primary"
                                    >
                                        <ShoppingCartOutlined />
                                    </Badge>
                                </MenuItem>
                            </Link>

                            <div
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                    cursor: "pointer",
                                }}
                                className={
                                    currentUser.currentUser == null
                                        ? "hidden"
                                        : null
                                }
                                onClick={handleClick}
                            >
                                <NavbarAuth
                                    className={
                                        currentUser.currentUser == null
                                            ? "hidden"
                                            : null
                                    }
                                >
                                    <ExitToApp></ExitToApp>
                                </NavbarAuth>
                            </div>
                        </Right>
                    ) : (
                        <></>
                    ) : (
                        <></>
                    )}
                </Wrapper>
                <MobileNavbar></MobileNavbar>
                {showMenu && (
                    <OverlayContainer>
                        <ModalContainer></ModalContainer>
                        <Overlay>
                            <ModalDetails>
                                <ModalItemContainer>
                                    <LeftContainer
                                        onClick={() => setShowMenu(false)}
                                    >
                                        <Close></Close>Close
                                    </LeftContainer>
                                    <MenuContainer>
                                        <Link
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            to="/"
                                            onClick={() => setShowMenu(false)}
                                        >
                                            <ModalTitle>Home</ModalTitle>
                                        </Link>
                                        <Link
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            to="/about"
                                            onClick={() => setShowMenu(false)}
                                        >
                                            <ModalTitle>About Us</ModalTitle>
                                        </Link>
                                        <Link
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            to="/background"
                                            onClick={() => setShowMenu(false)}
                                        >
                                            <ModalTitle>
                                                Background Story
                                            </ModalTitle>
                                        </Link>
                                        <Link
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            to="/demo"
                                            onClick={() => setShowMenu(false)}
                                        >
                                            <ModalTitle>
                                                Demonstration
                                            </ModalTitle>
                                        </Link>
                                        <Link
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            to="/gallery"
                                            onClick={() => setShowMenu(false)}
                                        >
                                            <ModalTitle>
                                                Gallery
                                            </ModalTitle>
                                        </Link>
                                        <Link
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            to="/product/G10"
                                            onClick={() => setShowMenu(false)}
                                        >
                                            <ModalTitle>
                                                The Product
                                            </ModalTitle>
                                        </Link>
                                        <Link
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            to="/search"
                                            onClick={() => setShowMenu(false)}
                                        >
                                            <ModalTitle>
                                                Service & Repair
                                            </ModalTitle>
                                        </Link>
                                        <Link
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                            to="/contact"
                                            onClick={() => setShowMenu(false)}
                                        >
                                            <ModalTitle>Contact Us</ModalTitle>
                                        </Link>
                                    </MenuContainer>
                                </ModalItemContainer>
                            </ModalDetails>
                            <ModalDetails>
                            </ModalDetails>
                            <ModalDetails>
                                <Left></Left>
                                <RightContainer>
                              
                                </RightContainer>
                            </ModalDetails>
                        </Overlay>
                    </OverlayContainer>
                )}
            </Container>
            <ScrollContainer>
                <ScrollText>
                    AIR VITAMIN - Your Essential Health Partner for Modern Living
                </ScrollText>
                <MobileScrollText>
                AIR VITAMIN - Your Essential Health Partner for Modern Living
                </MobileScrollText>
            </ScrollContainer>
        </>
    );
};

export default Navbar;
