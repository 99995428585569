import styled from "styled-components";
import { mobile, tablet } from "../responsive";

const Container = styled.div`
    background: #f5fafd;
    display: grid;
    align-items: center;
    flex-direction: row;

    ${mobile({ padding: "0rem 0", display: "flex", flexDirection: "column", paddingTop: "0" })}
    ${tablet({ padding: "0rem 0", display: "flex", flexDirection: "column" })}
`;
const Title = styled.h1`
    padding: 0 2rem;
    font-family: FuturaPT-Bold;
    font-size: 2.5rem;
    color: #ac997b;
    text-align: center;
    margin: 0rem 0rem 0rem 0;
    ${mobile({
        display: "block",
        textAlign: "center",
        fontSize: "1.5rem",
        padding: "0 2rem",
    })}
    ${tablet({
        display: "block",
        textAlign: "center",
        fontSize: "1.5rem",
        padding: "0 2rem",
    })}
`
const AboutContainer = styled.div`
    display: grid;
    justify-content: center;
    ${mobile({ display: "block" })}
    ${tablet({ display: "block" })}
`;

const Desc = styled.div`
    margin: 3rem auto; 
    font-size: 1.6rem;
    font-weight: 300;
    margin-top: 0rem;
    line-height: 1.5;
    max-width: 50vw; 

    ${mobile({ display: "block", fontSize: "1.3rem", maxWidth: "90vw" })} 
    ${tablet({ display: "block", fontSize: "1.3rem", maxWidth: "70vw" })}
`;


const LeftA = styled.div`
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${mobile({ height: "50vh" })}
    ${tablet({ height: "100%", paddingTop: "6rem" })}
`;

const ImageGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));  
    width: 100%;

    ${mobile({ 
        gridTemplateColumns: "1fr",  /* Single column layout for smaller screens */
        gap: "0.5rem" 
    })}
    ${tablet({ 
        gridTemplateColumns: "repeat(2, 1fr)", 
        gap: "1rem" 
    })}
`;


const ImageItem = styled.div`
    position: relative; /* Needed to position the overlay */
    width: 100%; 
    height: 40vh; /* Adjust height as needed */
    background: url(${(props) => props.img});
    background-size: cover; /* Ensures the image fills the container */
    background-repeat: no-repeat; 
    background-position: center; /* Centers the image */
    overflow: hidden; /* Ensures the overlay stays within bounds */
`;

const TitleOverlay = styled.div`
    position: absolute; /* Position the overlay relative to ImageItem */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Covers the entire image */
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex; 
    justify-content: center;
    align-items: center; 
    color: yellow;
    font-size: 3rem; 
    font-family: FuturaPT-Bold;
    text-align: center; 
    z-index: 1; /* Ensures overlay stays on top */
`;



const setA = [
    {
        url: "https://ik.imagekit.io/dnddecpho/airvitamin/Screenshot_20241007_152016~3_w9NSCJw5i.jpg?updatedAt=1732898457323",
        title: "Sleeping",
    },
    {
        url: "https://ik.imagekit.io/dnddecpho/airvitamin/Screenshot_20241007_150936~3_50zwdzHfK.jpg?updatedAt=1732898453828",
        title: "Exercise",
    },
    {
        url: "https://ik.imagekit.io/dnddecpho/airvitamin/Screenshot_20241007_151712~4_qkYKW9TqE.jpg?updatedAt=1732898456707",
        title: "Balance Diet",
    },
];

const setB = [
    {
        url: "https://ik.imagekit.io/dnddecpho/airvitamin/Screenshot_20241007_153608~2_HLeVXyeF_.jpg?updatedAt=1732898456695",
        title: "Wifi Tower",
    },
    {
        url: "https://ik.imagekit.io/dnddecpho/airvitamin/Screenshot_20241007_153713~2_a0iT4QKwJ.jpg?updatedAt=1732898456687",
        title: "Electrical Appliances",
    },
    {
        url: "https://ik.imagekit.io/dnddecpho/airvitamin/Screenshot_20241007_153431~2_yHcyhYzSg.jpg?updatedAt=1732898456701",
        title: "Home Wifi Router",
    },
    {
        url: "https://ik.imagekit.io/dnddecpho/airvitamin/Screenshot_20241007_153830~2_y5eDceT1a.jpg?updatedAt=1732898457508",
        title: "House Electric",
    },
];

const setC = [
    {
        url: "https://ik.imagekit.io/dnddecpho/airvitamin/Screenshot_20241007_171819~2_6XtRjj8IV.jpg?updatedAt=1732898453897",
        title: "Bacteria",
    },
    {
        url: "https://ik.imagekit.io/dnddecpho/airvitamin/Screenshot_20241007_171936~3_qsOEkoO9jD.jpg?updatedAt=1732898453939",
        title: "Parasites",
    },
    {
        url: "https://ik.imagekit.io/dnddecpho/airvitamin/Screenshot_20241007_172008~3_Y9BPZJSIsh.jpg?updatedAt=1732898454000",
        title: "Mold",
    },
    {
        url: "https://ik.imagekit.io/dnddecpho/airvitamin/Screenshot_20241007_171853~3_HTV3SVuPIg.jpg?updatedAt=1732898453894",
        title: "Virus",
    },
    {
        url: "https://ik.imagekit.io/dnddecpho/airvitamin/Screenshot_20241007_171723~2_635OiVh1p.jpg?updatedAt=1732898457632",
        title: "Mites",
    },
];

const setD = [
    {
        url: "https://ik.imagekit.io/dnddecpho/airvitamin/Screenshot_20241007_172920~2_Yi00vRjS7.jpg?updatedAt=1732898453953",
        title: "Toxic Gas",
    },
    {
        url: "https://ik.imagekit.io/dnddecpho/airvitamin/Screenshot_20241007_172913~2_8024sP00Bi.jpg?updatedAt=1732898453824",
        title: "Air Pollution",
    },
    {
        url: "https://ik.imagekit.io/dnddecpho/airvitamin/Screenshot_20241007_173131~2_-lBIHy9Xl.jpg?updatedAt=1732898453812",
        title: "Formaldehyde",
    },
    {
        url: "https://ik.imagekit.io/dnddecpho/airvitamin/Screenshot_20241007_173233~2_WsWKP2xDFo.jpg?updatedAt=1732898453957",
        title: "Chemical",
    },
];

const setE = [
    {
        url: "https://ik.imagekit.io/dnddecpho/airvitamin/Screenshot_20241007_183615~2_udhKWa-NL.jpg?updatedAt=1732898453859",
        title: "Healthy Life",
    },
];


const Background = () => {
    const isMobile = window.innerWidth <= 768;
    return (
        <>
            <Container>
                <ImageGridContainer>
                {(isMobile ? [setA[0]] : setA).map((img, index) => (
                        <ImageItem key={index} img={img.url} > <TitleOverlay className="title-overlay">{img.title}</TitleOverlay></ImageItem>
                    ))}
                </ImageGridContainer>
                <LeftA>
                    <AboutContainer>
                
                        <Title>Health VS Wealth </Title>
                        <Desc>
                            Many people strive for financial stability, yet health challenges persist despite efforts to eat well and exercise. Rising medical mosts often complicate this struggle.
                        </Desc>
                    </AboutContainer>
                </LeftA>
            </Container>
            <Container>
                <ImageGridContainer>
                {(isMobile ? [setB[0]] : setB).map((img, index) => (
                        <ImageItem key={index} img={img.url} > <TitleOverlay className="title-overlay">{img.title}</TitleOverlay></ImageItem>
                    ))}
                </ImageGridContainer>
                <LeftA>
                    <AboutContainer>
                
                        <Title>Overlooked Factors</Title>
                        <Desc>
                            Radiation: Our daily lives mare filled with various forms of radiation from devices like smartphones, Wi-Fi routers, and electrical appliances, which are classified as non-ionizing radiation. When exposure is too frequent or too strong, it may lead to the dissociation of surrounding molecules, potentially causing effects similar to ionizing radiation and resulting in unknown long-term health effects.
                        </Desc>
                    </AboutContainer>
                </LeftA>
            </Container>
            <Container>
                <ImageGridContainer>
                {(isMobile ? [setC[0]] : setC).map((img, index) => (
                        <ImageItem key={index} img={img.url} > <TitleOverlay className="title-overlay">{img.title}</TitleOverlay></ImageItem>
                    ))}
                </ImageGridContainer>
                <LeftA>
                    <AboutContainer>
                
                        <Title>Immune System Strain</Title>
                        <Desc>
                            The constant exposure to bacteria, viruses, and other pathogens can overwhelm our immune systems. While we rest at night, our bodies are still fighting off these microorganisms, potentially leading to chronic stress on our immune systems.
                        </Desc>
                    </AboutContainer>
                </LeftA>
            </Container>
            <Container>
                <ImageGridContainer>
                {(isMobile ? [setD[0]] : setD).map((img, index) => (
                        <ImageItem key={index} img={img.url} > <TitleOverlay className="title-overlay">{img.title}</TitleOverlay></ImageItem>
                    ))}
                </ImageGridContainer>
                <LeftA>
                    <AboutContainer>
                
                        <Title>Air Pollution</Title>
                        <Desc>
                            Beyond particulate matter, air quality is affected by chemical ionization and electromagnetic waves, introducing various harmful elements into our environment. This pollution can exacerbate health issues and weaken our overall well-being.
                        </Desc>
                    </AboutContainer>
                </LeftA>
            </Container>
            <Container>
                <ImageGridContainer>
                {(isMobile ? [setE[0]] : setE).map((img, index) => (
                        <ImageItem key={index} img={img.url} > <TitleOverlay className="title-overlay">{img.title}</TitleOverlay></ImageItem>
                    ))}
                </ImageGridContainer>
                <LeftA>
                    <AboutContainer>
                
                        <Title>Healthy Life</Title>
                        <Desc>
                            Addressing these concerns requires a multifaceted approach, including improved public health policies, community awareness, and individual lifestyle adjustments.
                        </Desc>
                    </AboutContainer>
                </LeftA>
            </Container>
        </>
    );
};

export default Background;
