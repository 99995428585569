import { useState } from "react";
import styled from "styled-components";

const GalleryContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
`;

const MainImageContainer = styled.div`
  width: 80%;
  max-width: 1200px;
  height: 60vh;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const MainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ThumbnailGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 10px;
  width: 100%;
  max-width: 1200px;
`;

const ThumbnailContainer = styled.div`
  position: relative;
  border: ${(props) =>
    props.isSelected ? "2px solid #333" : "2px solid transparent"};
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: border-color 0.3s ease;
  &:hover {
    border-color: #666;
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Gallery = () => {

    const images = [
        {
          id: 1,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG-20170625-WA0006_hfjFaGnEj.jpg?updatedAt=1733064640041"
        },
        {
          id: 2,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG-20170226-WA0003_7dfIEUg3y.jpg?updatedAt=1733064637998"
        },
        {
          id: 3,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG-20170606-WA0030_eSnjCL2rt.jpg?updatedAt=1733064637947"
        },
        {
          id: 4,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG-20170528-WA0002_EN5IWvYhT.jpg?updatedAt=1733064637899"
        },
        {
          id: 5,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG-20170528-WA0012_EyGPhG06OQ.jpg?updatedAt=1733064637543"
        },
        {
          id: 6,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG-20161218-WA0026_gKNVchNwz.jpg?updatedAt=1733064637500"
        },
        {
          id: 7,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG-20161218-WA0017_Ie_HIrsNsu.jpg?updatedAt=1733064637295"
        },
        {
          id: 8,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG-20170226-WA0000_SffGIe1gl.jpg?updatedAt=1733064637230"
        },
        {
          id: 9,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG-20160927-WA0014_tT-RyORDE.jpg?updatedAt=1733064637185"
        },
        {
          id: 10,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG-20160927-WA0012_6M1rCwMc0.jpg?updatedAt=1733064637194"
        },
        {
          id: 11,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG-20160927-WA0008_JW9-RyXBP.jpg?updatedAt=1733064637099"
        },
        {
          id: 12,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG-20160927-WA0007_SAeh9aGOA3.jpg?updatedAt=1733064634503"
        },
        {
          id: 13,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG-20160401-WA0001_Xaab9TXxa.jpg?updatedAt=1733064634571"
        },
        {
          id: 14,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG-20160827-WA0003_C6JZODNRgD.jpg?updatedAt=1733064634526"
        },
        {
          id: 15,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG-20160827-WA0023_omFiS45Zvt.jpg?updatedAt=1733064634474"
        },
        {
          id: 16,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG-20160802-WA0005_TF8aIKxtA.jpg?updatedAt=1733064634466"
        },
        {
          id: 17,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG-20160927-WA0003_FZIB4KU4kV.jpg?updatedAt=1733064634463"
        },
        {
          id: 18,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG-20160330-WA0005_nFoTFgIjZ2.jpg?updatedAt=1733064634434"
        },
        {
          id: 19,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG-20160330-WA0011_otYafLiau.jpg?updatedAt=1733064634429"
        },
        {
          id: 20,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG-20160802-WA0015_aQfwu0tUf.jpg?updatedAt=1733064634331"
        },
        {
          id: 21,
          url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG-20160330-WA0004_iwlk1Hra7.jpg?updatedAt=1733064634197"
        },
        {
            id: 22,
            url: "https://ik.imagekit.io/dnddecpho/airvitamin/DSC04008_C9gp3TP1n.png?updatedAt=1733066388935"
        },
        {
            id: 23,
            url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG_20170610_164319_LSlwEWr25.png?updatedAt=1733066388770"
        },
        {
            id: 24,
            url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG_20170609_212336_ALboY-wqL.png?updatedAt=1733066388710"
        },
        {
            id: 25,
            url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG_20170722_162312_JhlAKMHS9.png?updatedAt=1733066388649"
        },
        {
            id: 26,
            url: "https://ik.imagekit.io/dnddecpho/airvitamin/IMG_20170805_175409_TSYJmst7-.png?updatedAt=1733066502770"
        }
      ];
      

  const [mainImage, setMainImage] = useState(images[0].url);

  const handleThumbnailClick = (url) => {
    setMainImage(url);
  };

  return (
    <GalleryContainer>
      <MainImageContainer>
        <MainImage src={mainImage} alt="Main Display" />
      </MainImageContainer>
      <ThumbnailGrid>
        {images.map((image) => (
          <ThumbnailContainer
            key={image.id}
            isSelected={mainImage === image.url}
            onClick={() => handleThumbnailClick(image.url)}
          >
            <Thumbnail src={image.url} alt={`Thumbnail ${image.id}`} />
          </ThumbnailContainer>
        ))}
      </ThumbnailGrid>
    </GalleryContainer>
  );
};

export default Gallery;
