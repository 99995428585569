import { useEffect, useState } from "react";
import styled from "styled-components";
import Product from "./AlternateProduct";
import { mobile, tablet } from "../responsive";
import { publicRequest } from "../requestMethods";

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-bottom: 3rem;
    ${mobile({ padding: "0px" })}
    ${tablet({ padding: "0px" })}
`;

const Container = styled.div`
    padding: 0 4rem;
    flex-grow: 5;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    ${mobile({
        display: "grid",
        gridTemplateColumns: "50% 50%",
        padding: "1rem 0 2rem 0",
    })}
    ${tablet({
        display: "grid",
        gridTemplateColumns: "50% 50%",
        padding: "1rem 0 2rem 0",
    })}
`;

const Search = ({ cat, filters }) => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        const getProducts = async () => {
            try {
                const res = await publicRequest.get(`/products`);
                // Filter out the product with title "G10"
                const filteredProducts = res.data.filter((product) => product.title !== "G10");
                setProducts(filteredProducts);
            } catch (err) {
                console.error("Error fetching products:", err);
            }
        };
        getProducts();
    }, []);
    

    useEffect(() => {
        cat &&
            setFilteredProducts(
                products.filter((item) =>
                    Object.entries(filters).every(([key, value]) =>
                        item[key].includes(value)
                    )
                )
            );
    }, [products, cat, filters]);

    return (
        <MainContainer>
            <Container>
                {filteredProducts
                          .map((item) => (
                              <Product item={item} key={`${item.title}`} />
                          ))}
            </Container>
        </MainContainer>
    );
};

export default Search;
